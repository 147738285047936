html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--color-background);
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
}

input[type='checkbox'],
input[type='radio'] {
  transform: scale(1.1);
  width: 1.2rem;
  height: 1.2rem;
}

.auth {
  &__screen {
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__error-title {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__error-message {
    padding: 1rem;
    max-width: 600px;

    font-size: 0.872rem;
  }
}

.content {
  display: flex;
  margin: 0 auto;

  color: var(--color-text);
  padding: 2rem 1.5rem 4rem 1.5rem;

  &__sidebar {
    width: 300px;
    margin-right: 1rem;
  }

  &__main {
    flex: 1;
  }
}

.modal .modal-content {
  max-width: 800px;
}

.subscriptions-modal .modal-content {
  max-width: 1280px;
  padding: 0.75rem;
}

.subscriptions {
  &__title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.5rem;
  }

  &__roles {
    user-select: none;
  }

  &__roles-input {
    display: flex;
    margin: 0.25rem 0;
    align-items: center;

    span {
      padding-top: 2px;
    }

    input {
      margin-right: 0.5rem;
    }
  }

  &__categories {
    display: flex;
  }

  &__category {
    display: flex;
    align-items: center;
    padding-right: 1rem;

    span {
      padding-left: 0.5rem;
    }
  }

  &__tabs {
    max-width: 80%;
    font-size: 0.8rem;
  }

  &__new {
    display: flex;
    margin-bottom: 0.5rem;
  }

  &__name {
    width: 270px;
    margin-right: 0.5rem;
  }

  &__frequency {
    width: 150px;
  }

  &__filters-title {
    padding: 0.5rem 0;
    font-weight: 500;
  }

  &__filters {
    gap: 2rem;
    display: grid;
    margin-bottom: 0.5rem;

    grid-template-columns: 1fr 1fr;

    .react-checkbox-tree ol {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .rct-title {
      white-space: nowrap;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__general-buttons > button {
    margin-right: 0.5rem;
  }
}
